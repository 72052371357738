.popupContainer {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 50;
    animation: slideIn 0.5s ease-out;
  }
  
  .popup {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 20px;
    min-width: 16rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    border: 1px solid #e5e7eb;
    align-items: center;
  }
  
  .popupIcon {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #dbeafe;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: all 0.3s ease;
  }
  
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #2563eb;
  }
  
  .changing {
    transform: scale(0) rotate(180deg);
  }
  
  .popupContent {
    flex-grow: 1;
    overflow: hidden;
  }
  
  .popupText {
    transition: all 0.3s ease;
  }
  
  .popupText h4 {
    font-weight: bold;
    color: #111827;
    margin: 0 0 0.25rem 0;
    font-size: 0.875rem;
    font-size: 20px;
  }
  
  .popupText p {
    font-size: 15px;
    color: #6b7280;
    margin: 0;
  }
  
  .popupClose {
    color: #6b7280;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popupClose:hover {
    color: #374151;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
  .popupContainer {
   margin: 2%;
   right: 0;
    }
    .popup {
      right: auto;
      left: auto;

      margin: auto;
       }
   
  }