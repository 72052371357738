/* Déclaration pour la police ArialRoundedBold */
@font-face {
  font-family: 'ArialRoundedBold';
  src: url('https://api.epiceriebellis.fr/siteweb.bellis/assets/fonts/ArialRoundedBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Déclaration pour la police Quicksand */
@font-face {
  font-family: 'Quicksand';
  src: url('https://api.epiceriebellis.fr/siteweb.bellis/assets/fonts/Quicksand.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Définir les styles globaux */
body {
  margin: 0;
  font-family: 'Quicksand', sans-serif; /* Appliqué aux éléments avec la classe .secondary-text */
}

