html {
  background-color: #F5F5F7;
}
.announcement-banner {
  background-color: white;
  text-align: center;
  padding: 8px;
  position: relative;
}

.announcement-banner .close-button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.nav-container {
  background: white;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 50%;
  align-items: center;
margin: auto;
border-radius: 15px;
margin-top: 30px;
}

.nav-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo-container img {
  height: 50px;
  width: auto;
}

.profile-button {
  background: transparent;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.hero-section {
  text-align: center;
  padding: 30px 20px;
  max-width: 1200px;
  margin: 0 auto;
}



.hero-subtitle {
  font-size: 18px;
  color: #666;
  max-width: 600px;
  margin: 0 auto 30px;
}

.location-button {
  background: #F5F5F7;
  border: 2px solid #27ad7a;
  color: #1b4332;
  padding: 12px 30px;
  border-radius: 30px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.location-button:hover {
  background: #27ad7a;
  color: white;
}

.outlined-text {
  -webkit-text-fill-color: #27ad7a ;
}


/* Style pour le thème clair */
.app-theme.light {
  background-color: #ffffff;
  color: #000000;
}

/* Style pour le thème sombre */
.app-theme.dark {
  background-color: #1e1e1e;
  color: #ffffff;
}
/* Styles pour le mode clair */
html[data-theme='light'] {
  background-color: #F0F0F2;
  color: #000;
}

/* Styles pour le mode sombre */
html[data-theme='dark'] {
  background-color: #1d1b1e;
  color: #FFF;
}
/* Styles supplémentaires pour adapter les éléments */