.hero-overlay {
  position: absolute;
  inset: 0;
  width: 100%;
  margin: auto;
}

.hero {
  height: 70vh;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  background-color: #27ad7a;
}


.burger-button .menu-icon {
  color: #fff; /* Couleur verte par défaut */
}

.burger-button.scrolled .menu-icon {
  color: #27ad7a; /* Couleur jaune après 50 pixels de scroll */
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 60%;
  z-index: 50;
  padding: 1rem 2rem;
  margin: auto;
  margin-top: 30px;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.header-scrolled {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.header-container {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.burger-button {
  padding: 0.5rem;
  cursor: pointer;
  z-index: 51;
  transition: color 0.3s ease;
  background-color: transparent;
  border: none;
}

.logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo {
  height: 60px;
  transition: height 0.3s ease;
}

.logo-scrolled {
  height: 40px;
}


.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.user-icon-svg {
  width: 24px;
  height: 24px;
  transition: color 0.3s ease;
}


.button-primary-H,
.button-secondary {
  text-align: center;
    background: white;
    color: #1d453d;
    border: none;
    padding: 1rem 2rem;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    font-size: 15px;
}
.button-primary-H:hover,
.button-secondary:hover {
  background: #004D4D;
  color: #fff;
  transform: scale(1.05); /* Agrandit le bouton de 5% */
  transition: transform 0.2s ease; /* Ajoute une transition douce */
}
.mobile-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  transition: transform 0.3s ease;
  transform-origin: top;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.mobile-menu.closed {
  transform: scaleY(0);
}

.mobile-menu.open {
  transform: scaleY(1);
}

.menu-list {
  padding: 0.5rem 0;
}

.menu-item {
  padding: 0.75rem 2rem;
}

.menu-link {
  display: block;
  transition: color 0.2s ease;
}



.menu-link.active {
  color: #fff;
  font-weight: 600;
}

.hero-image-container {
  position: absolute;
  inset: 0;
  transition: opacity 0.5s ease;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute; /* Rend possible le positionnement en bas */
  bottom: 0; /* Positionne l'image en bas du conteneur */
  left: 50%; /* Centre l'image horizontalement */
  transform: translateX(-50%); /* Corrige le décalage du centrage */
}

.hero-content {
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 800px;
  padding: 0 2rem;
  opacity: 0;
  transform: translateY(20px);
}

.hero-visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease, transform 1s ease;
}

.hero-title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: #fff;
  width: 60%;
  margin: auto;
  margin-bottom: 20px;
  font-family: 'ArialRoundedBold', sans-serif;
}

.hero-subtitle-p {
  font-size: 15px;
  color: #fff;
  margin-bottom: 2rem;
  line-height: 1.4;
}

.button-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.menu-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.menu-list {
  justify-content: center;
}
.menu-link {
  text-decoration: blink;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}
.menu-link:hover {
  text-decoration: blink;
  background-color: #27ad7a;
  color: #fff;
  font-weight: bold;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  transform: scale(1.05);
}
.menu-list {
  height: 50px;
  align-items: center;
}
.scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  z-index: 20;
}
.mobile-menu.open {
  margin-top: 20px;
  border-radius: 15px;
}
.logo-container img {
  height: 50px;
  width: auto;
}

.hidden {
  opacity: 0; /* Rend l'image invisible */
}





@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-20px);
  }
  60% {
    transform: translateX(-50%) translateY(-10px);
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 30px;
  }
  .hero-title {
    width: auto;
}
  p .hero-subtitle {
    font-size: 1.25rem;
  }
  .menu-list {
    display: flex;
    flex-direction: column; /* Par défaut, les liens sont en colonne pour la version mobile */
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .button-container {
    flex-direction: column;
    align-items: stretch;
    padding: 0 1rem;
  }
  .menu-list {
    height: auto;
    align-items: center;
  }
  .button-primary,
  .button-secondary {
    text-align: center;
  }
  
  .header {
    padding: 1rem;
    width: 80%;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 25px;
  }
  
  .hero-content {
    padding: 0 1rem;
  }
  
  .logo {
    height: 40px;
  }
  
  .logo-scrolled {
    height: 32px;
  }
}

